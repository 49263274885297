.__admin_sidebar a.active {
  background-color: rgba(115, 108, 108, 0.3);
  font-weight: 600;
  color: white;
}

a.active,
.__admin_layout a.active {
  background-color: rgba(218, 165, 32, 0.1);
  color: goldenrod;
  font-weight: 600;
}
